.header {
  background-color: $primary;
  margin-bottom: 60px;

  .logout-button {
    @extend .nav-link;
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
