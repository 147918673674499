$primary-100: #FFE199;
$primary-300: #E8C674;
$primary-500: #E8BD56;
$primary: #E8B028;
$primary-900: #CC9B23;

$secondary-100: #99B9FF;
$secondary-300: #7498E8;
$secondary-500: #5683E8;
$secondary: #2763E8;
$secondary-900: #2257CC;

$neutral-50: #F8FAFC;
$neutral-100: #F1F5F9;
$neutral-200: #EFE8F0;
$neutral-300: #CBD5E1;
$neutral-400: #94A3B8;
$neutral-500: #64748B;
$neutral-600: #475569;
$neutral-700: #334155;
$neutral-800: #1E293B;
$neutral-900: #0F172A;

$positive-100: #73BF83;
$positive-300: #53A565;
$positive-500: #29A544;
$positive: #11A531;
$positive-900: #00801C;

$danger-100: #E57E7E;
$danger-300: #CD5C5C;
$danger-500: #CD3333;
$danger: #CD1515;
$danger-900: #A60000;

$white: #FFFFFF;
$black: #000000;
