h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
}

p {
  font-size: 16px;
}

a {
  color: $primary;

  &:hover {
    color: $primary-900;
  }
}
