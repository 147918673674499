.button {
  all: unset;
  border-radius: $border-radius-small;
  padding: 8px 12px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 16px;

  &-primary {
    background-color: $primary;
    color: $black;

    &:hover {
      background-color: $primary-900;
    }

    &:active {
      background-color: $primary-500;
    }

    &:disabled {
      background-color: $primary-100;
    }
  }

  &-secondary {
    background-color: $secondary;
    color: $white;

    &:hover {
      background-color: $secondary-900;
    }

    &:active {
      background-color: $secondary-500;
    }

    &:disabled {
      background-color: $secondary-100;
      color: $black;
    }
  }
}
