.login {
  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    display: flex;
    flex-direction: column;
  }
}
