.heading {
  background-color: $primary-100;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 40px;
  height: 400px;

  @include lg-min {
    height: 300px;
    flex-direction: row;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    z-index: 2;

    @include lg-min {
      width: 40%;
    }

    h1,
    p {
      margin: 0;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 100%;

    @include lg-min {
      width: 60%;
    }

    &::before {

      @include lg-min {
        content: '';
        display: block;
        position: absolute;
        background-color: $primary-100;
        width: 60%;
        height: 150%;
        transform: skew(-20deg);
        left: -50%;
        z-index: 1;
      }
    }

    img {
      min-width: 100%;
      min-height: 100%;
    }
  }
}
