label {
  width: 100%;
}

input,
select,
textarea {
  all: unset;
  display: block;
  border-radius: $border-radius;
  background-color: $neutral-100;
  padding: 10px;
  filter: $drop-shadow;
  margin-bottom: 16px;
  margin-top: 8px;
  text-align: left;

  &:disabled {
    background-color: $neutral-200;
  }
}

select {
  background-image: url(../../../../public/icons/chevron-down-icon.svg);
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}

textarea {
  height: 100px;
}
