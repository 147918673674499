.card {
  all: unset;
  background-color: $neutral-100;
  border-radius: $border-radius;
  display: block;
  filter: $drop-shadow;
  margin-bottom: 16px;
  padding: 20px;
  height: 100%;

  &__destination,
  &__country {
    height: 100%;
    display: flex;
    flex-direction: column;

    // All first level elements.
    &>*:not(:first-child) {
      margin-top: 20px;
    }

    &--image {
      position: relative;
      width: 100%;
      height: 250px;
      overflow: hidden;
      border-radius: $border-radius;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &--category {
      position: absolute;
      background-color: $primary;
      border-radius: $border-radius;
      padding: 10px;
      font-size: 14px;
      font-weight: bold;
      top: 10px;
      left: 10px;
    }

    &--options {
      position: absolute;
      top: 10px;
      right: 10px;
      text-align: right;

      svg {
        fill: $white;
        cursor: pointer;
      }

      .options {
        background-color: $white;
        border-radius: $border-radius;
        padding: 10px;

        &__button {
          all: unset;
          font-weight: bold;
          cursor: pointer;

          &.delete {
            color: $danger;
          }
        }
      }
    }

    &--description {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5; /* number of lines to show */
      line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    &--link {
      font-weight: bold;
      margin-top: auto !important;
    }
  }
}
