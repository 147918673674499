.destination-modal {
  overflow-y: scroll;
  z-index: 2;

  .card-wrapper {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  input,
  select,
  textarea {

    &:not(.button) {
      width: calc(100% - 32px);
    }
  }
}
