.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($neutral-800, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 16px;
}

.modal {
  max-width: 800px;
  width: 100%;
  max-height: 80%;
  overflow-y: scroll;

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--close {
      cursor: pointer;
    }
  }
}
